<template>
  <div class="router" @touchmove.prevent @mousewheel.prevent>
    <img
      class="close"
      src="@/assets/images/main/mobile/close_2.png"
      alt=""
      @click="handleClose"
    />
    <!-- <img class="logo" src="@/assets/images/main/mobile/logo.png" /> -->
    <div :class="['menu']">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['menu-item', activeBtn === item.name && 'active']"
        @click="handleMenuLink(item.link, item.name)"
      >
        <div
          :class="['menu-item-name', item.children && 'menu-item-name-arrow']"
        >
          {{ item.name }}
        </div>
        <div v-if="activeBtn === item.name && item.children" class="subList">
          <div
            class="subList-item"
            v-for="(subItem, subItemIndex) in item.children"
            :key="subItemIndex"
            @click="handleMenuLink(subItem.link)"
          >
            {{ subItem.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="footerLink">
      <img
        v-for="(item, index) in list"
        :key="index"
        class="footerLink-item"
        :src="item.imgUrl"
        :alt="item.name"
        @click="handleToLink(item.link)"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    isScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeBtn: "Start Gaming",
      tabList: [
        {
          name: "Start Gaming",
          link: "",
          children: [
            {
              name: "How To Play",
              link: `https://special-event.yuliverse.com/activate`,
            },
            {
              name: "Activate Gen1 Yuli",
              link: "https://special-event.yuliverse.com/activate/YuliGen1",
            },
            {
              name: "Activate Gen2 Yuli",
              link: "https://special-event.yuliverse.com/activate/YuliGen2",
            },
            {
              name: "Team Annihilation",
              link: "https://special-event.yuliverse.com/Annihilation",
            },
          ],
        },
        {
          name: "Mint Yuli",
          link: "",
          children: [
            {
              name: "Mint Gen1 Yuli",
              link: "https://special-event.yuliverse.com/sales/YuliGen1",
            },
            {
              name: "Mint Gen2 Yuli",
              link: "https://special-event.yuliverse.com/sales/YuliGen2",
            },
          ],
        },
        // {
        //   name: "Stake",
        //   link: "https://special-event.yuliverse.com/sales/YuliStaking",
        // },
        // {
        //   name: "ART Airdrop",
        //   link: "https://airdrop.yuliverse.com/",
        // },
        // {
        //   name: "MoonRing",
        //   link: "https://moonring.ai",
        // },
        {
          name: "CAMPAIGN",
          link: "https://special-event.yuliverse.com/",
        },
        {
          name: "MARKETPLACE",
          link: "https://marketplace.yuliverse.com/",
        },
      ],
      list: [
        {
          imgUrl: require("@/assets/images/main/icon_discord.png"),
          name: "Discord",
          link: "https://discord.com/invite/yuliverse-official",
        },
        {
          imgUrl: require("@/assets/images/main/icon_telegram.png"),
          name: "Telegrand",
          link: "http://t.me/YuliverseOfficial",
        },
        {
          imgUrl: require("@/assets/images/main/icon_twitte.png"),
          name: "Twitter",
          link: "http://twitter.com/TheYuliverse",
        },
      ],
    };
  },
  mounted() {
    this.preload();
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleToLink(link) {
      window.open(link);
    },
    handleMenuLink(link, name) {
      if (link) {
        window.open(link);
      } else {
        this.activeBtn = name;
      }
    },
    preload() {
      var image = new Image();
      image.src = require("@/assets/images/main/mobile/router_bg.png");
    },
  },
};
</script>

<style lang="less" scoped>
.router {
  z-index: 999;
  width: 100%;
  // height: 768px;
  height: 100vh;
  position: fixed;
  top: 0;
  // background-image: url("~@/assets/images/main/mobile/router_bg.png");
  background: #1a1a21cc;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 0px 8px;
  box-sizing: border-box;

  .close {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 16px;
    right: 24px;
  }
  .logo {
    // width: 205px;
    // height: 107.89px;
    height: 13%;
  }
  .menu {
    width: 100%;
    padding: 64px 0 32px 0;
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1a1a21;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 295px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      font-family: "Tomorrow";
      color: #ffffffd9;

      &-name {
        margin-bottom: 1px;
        width: 100%;
        height: 48px;
        // background-color: #00000040;
        line-height: 48px;

        &-arrow {
          &::after {
            content: " ";
            width: 16px;
            height: 16px;
            // background-color: #fff;
            margin-left: 10px;
            display: inline-block;
            background-image: url("~@/assets/images/main/mobile/icon_arrow_up@2x.png");
            background-position: center;
            background-size: 100% 100%;
            transform: rotate(180deg);
          }
        }
      }
    }

    .subList {
      // background: #00000026;
      width: 100%;
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffffd9;
      margin-bottom: 1px;
      border-top: 1px solid #ffffff14;
      border-bottom: 1px solid #ffffff14;
      font-size: 14px;
      font-weight: 500;

      color: #ffffff99;
      text-align: center;
      font-family: "Tomorrow";
      font-style: normal;
      line-height: normal;
      text-transform: capitalize;

      &-item {
        width: 200px;
        height: 40px;
        line-height: 40px;

        &:hover {
          // color: #73d7ff;
          background-color: #73d7ff;
          border-radius: 8px;
          color: #000000d9;
          font-weight: 700;
        }
      }
      .subList-item + .subList-item {
        margin-top: 8px;
      }
    }
    .active {
      // color: #73d7ff;
      .menu-item-name-arrow {
        &::after {
          transform: rotate(360deg);
        }
      }
    }
  }
  .footerLink {
    // position: absolute;
    // bottom: 100px;
    margin: 9% auto 0;
    display: flex;
    justify-content: space-around;
    width: 160px;
    &-item {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
