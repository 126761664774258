<template>
  <div class="mainContent" :class="[iswebp ? 'webp' : 'png']">
    <div class="mainLinkBox">
      <!-- <img class="mainLinkBox-title" src="@/assets/images/main/art_title.png" alt="" />
      <img class="mainLinkBox-subTitle" src="@/assets/images/main/art_subTitle.png" alt="" /> -->
      <div class="btn-list">
        <div class="titleView-button" @click="handleOpenLink('market')">
          Buy NFT
        </div>
        <div class="titleView-button-2" @click="handleOpenLink('discord')">
          Join Discord
        </div>
      </div>
      <!-- <div class="steps">
        <div class="steps-item">
          <span class="steps-item-num">01</span>
          <span class="steps-item-text">Complete Quests</span>
        </div>
        <div class="steps-item">
          <span class="steps-item-num">02</span>
          <span class="steps-item-text">Collect Yuli Points</span>
        </div>
        <div class="steps-item">
          <span class="steps-item-num">03</span>
          <span class="steps-item-text">Get rewarded handsomely</span>
        </div>
      </div>

      <div class="toArt" @click="handleOpenLink('art')">Start Now</div> -->

      <!-- <div class="mainLink">
        <div class="mainLink-left" @click="handleOpenLink('whitePaper')">
          <img
            class="mainLink-icon"
            src="@/assets/images/main/icon_whitepappers.svg"
          />
          <div class="mainLink-text">Whitepaper</div>
        </div>
        <div class="mainLink-right" @click="handleOpenLink('getYuli')">
          <img
            class="mainLink-icon"
            src="@/assets/images/main/icon_play_black.svg"
          />
          <div class="mainLink-text">Mint Now</div>
        </div>
      </div> -->
      <!-- <div class="appLink">
        <img
          class="appLink-ios"
          @click="handleOpenLink('ios')"
          src="@/assets/images/main/ios.png"
        />
        <img
          class="appLink-google"
          @click="handleOpenLink('google')"
          src="@/assets/images/main/google.png"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import titleJson from "@/assets/json/title.json";
// import Lottie from "vue-lottie/src/lottie.vue";
import axios from "axios";

export default {
  // components: {
  //   Lottie,
  // },
  props: {},
  data() {
    return {
      iswebp: window.iswebp,
      participantsNumber: 0,
      titleOption: { animationData: titleJson },
    };
  },
  // mounted() {
  //   this.getData();
  // },
  methods: {
    handleOpenLink(str) {
      switch (str) {
        case "whitePaper":
          window.open(
            "https://metaport.gitbook.io/the-white-paper-of-yuliverse/"
          );
          break;
        case "getYuli":
          window.location.href =
            process.env.NODE_ENV === "production"
              ? "https://special-event.yuliverse.com/PolygonHero"
              : "https://umi.yuliverse.com/PolygonHero";

          break;
        case "blessing":
          window.location.href =
            "https://special-event.yuliverse.com/UnknownGuest";
          break;
        case "ios":
          window.open("https://apps.apple.com/hk/app/yuliverse/id6469319748");
          break;
        case "google":
          window.open(
            "https://play.google.com/store/apps/details?id=com.blockoor.yuliforoverseas"
          );
          break;
        case "art":
          window.open("https://airdrop.yuliverse.com/");
          break;
        case "Anni":
          window.open(
            process.env.NODE_ENV === "production"
              ? "https://special-event.yuliverse.com/Annihilation"
              : "https://umi.yuliverse.com/Annihilation"
          );
          break;
        case "discord":
          window.open("https://discord.com/invite/yuliverse-official");
          break;
        case "market":
          window.open("https://marketplace.yuliverse.com/");
          break;
      }
    },
    // getData() {
    //   axios
    //     .get(
    //       `https://yf.yuliverse.com/${
    //         process.env.NODE_ENV === "production" ? "prod" : "beta"
    //       }/hero_wl.json`
    //     )
    //     .then(
    //       (response) => {
    //         this.participantsNumber = response.data.total;
    //       },
    //       (response) => {
    //         this.participantsNumber = 0;
    //       }
    //     );
    // },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");

.mainContent {
  box-sizing: border-box;
  .webpbg("~@/assets/images/version2/mobile/bg/bg_1@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  display: flex;
  color: #fff;
  text-align: center;
  width: 100%;
  height: (100dvw / 375px) * 719px;
  min-height: 719px;
  font-family: "Josefin Sans";

  .btn-list {
    width: 240;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 159px;
  }

  .titleView {
    &-button {
      margin: 0 auto;
      cursor: pointer;
      background-image: url("~@/assets/images/main/new_btn_bg.png");
      background-size: 100% 100%;
      width: 240px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000d9;
      text-align: center;
      font-family: "Tomorrow";
      font-size: 18px;
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 24px;
    }

    &-button-2 {
      margin: 0 auto;
      cursor: pointer;
      background-image: url("~@/assets/images/main/btn_blue_s_272@2x.png");
      background-size: 100% 100%;
      width: 240px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000d9;
      text-align: center;
      font-family: "Tomorrow";
      font-size: 18px;
      font-weight: 700;
      text-transform: capitalize;
    }
  }

  .mainLinkBox {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &-title {
      width: 211px;
      height: 38px;
    }
    &-subTitle {
      width: 341px;
      height: 38px;
      margin-top: 5px;
    }
    .steps {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-item {
        width: 234px;
        height: 49px;
        background-image: url("~@/assets/images/main/step_bg.png");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        text-align: left;
        &-num {
          color: #c14600;
          text-align: center;
          font-family: "Josefin Sans";
          font-size: 28px;
          font-weight: 700;
          // line-height: 20px;
          opacity: 0.9;
        }
        &-text {
          margin-left: 10px;
          width: 164px;
          color: #e7993e;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
        }
      }
    }
    .steps-item + .steps-item {
      margin-top: 16px;
    }
    .toArt {
      cursor: pointer;
      margin-top: 22px;
      width: 240px;
      height: 44px;
      border-radius: 34px;
      border: 1px solid #710404;
      background: #c14600;
      line-height: 44px;
      text-align: center;
      color: #ffffff;
      text-align: center;
      font-family: "Josefin Sans";
      font-size: 16px;
      font-weight: 700;
    }

    .mainLink {
      // margin-top: 30px;
      display: flex;
      font-size: 14px;
      font-family: "Josefin Sans";
      font-weight: 700;
      text-align: center;
      flex-flow: row-reverse;
      &-left,
      &-right {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-left {
        background: #000;
        width: 148.55px;
        height: 37.24px;
        margin-left: 12px;
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 61px;
        background: rgba(0, 0, 0, 0.7);
        line-height: 36px;
      }
      &-right {
        color: rgb(32, 32, 32);
        width: 130px;
        height: 36px;
        line-height: 36px;
        border-radius: 63px;
        opacity: 1;
        border: 1px solid rgba(0, 102, 255, 0.4);
        background: #00f0ff;
      }
      &-text {
        // line-height: 50px;
        padding-top: 3.5px;
      }
      &-icon {
        width: 16.55px;
        height: 16.55px;
        margin-right: 8px;
      }
    }
    &-button {
      cursor: pointer;
      background-image: url("~@/assets/images/main/new_btn_bg.png");
      background-size: 100% 100%;
      width: 240px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000d9;
      text-align: center;
      font-family: "Tomorrow";
      font-size: 18px;
      font-weight: 700;
      text-transform: capitalize;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .appLink {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 80px;
    &-ios {
      width: 90px;
      margin-right: 5px;
    }
    &-google {
      width: 111px;
    }
  }
}
</style>
