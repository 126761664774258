<template>
  <div class="floating">
    <!-- <div class="floating-menu">
      <div
        v-for="(item, index) in menuList"
        :key="index"
        :class="[
          'floating-menu-item',
          isScroll && activeBtn !== item.link && 'menuScroll',
          activeBtn === item.link && 'active',
        ]"
        @click="handleMenuLink(item.link)"
      >
        {{ item.name }}
      </div>
    </div> -->
    <div class="floating-bottom">
      <div class="floating-bottom-list">
        <img
          class="floating-bottom-list-item"
          :src="list[threeImg].imgUrl"
          :alt="list[2].name"
          @click="handleToLink(list[2].link)"
          @mouseenter="handleImg(5)"
          @mouseleave="handleImgOVer(5)"
        />
        <img
          class="floating-bottom-list-item"
          :src="list[oneImg].imgUrl"
          :alt="list[0].name"
          @click="handleToLink(list[0].link)"
          @mouseenter="handleImg(3)"
          @mouseleave="handleImgOVer(3)"
        />
        <!-- <img
          class="floating-item"
          :src="list[twoImg].imgUrl"
          :alt="list[1].name"
          @click="handleToLink(list[1].link)"
          @mouseenter="handleImg(4)"
          @mouseleave="handleImgOVer(4)"
        /> -->
      </div>

      <div
        :style="{ display: `${clientHeight ? 'block' : 'none'}` }"
        class="backTop"
        @click="handleBackTop"
      >
        <img src="../assets/images/main/icon_top@2x.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeBtn: {
      type: String,
      default: "",
    },
    isScroll: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [
        {
          imgUrl: require("@/assets/images/main/icon_discord.png"),
          name: "Discord",
          link: "https://discord.com/invite/yuliverse-official",
        },
        {
          imgUrl: require("@/assets/images/main/icon_telegram.png"),
          name: "Telegrand",
          link: "http://t.me/YuliverseOfficial",
        },
        {
          imgUrl: require("@/assets/images/main/icon_twitte.png"),
          name: "Twitter",
          link: "http://twitter.com/TheYuliverse",
        },
        {
          imgUrl: require("@/assets/images/main/icon_discord_selet.png"),
        },
        {
          imgUrl: require("@/assets/images/main/icon_telegram_selet.png"),
        },
        {
          imgUrl: require("@/assets/images/main/icon_twitte_selet.png"),
        },
      ],
      clientHeight: 0,
      oneImg: 0,
      twoImg: 1,
      threeImg: 2,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
  },

  methods: {
    handleToLink(link) {
      window.open(link);
    },
    handleBackTop() {
      this.$emit("backTop");
    },
    handleScrollx() {
      this.clientHeight = window.pageYOffset;
    },
    handleMenuLink(link) {
      this.$emit("MenuLink", link);
    },
    handleImg(index) {
      if (index === 3) {
        this.oneImg = 3;
      }
      if (index === 4) {
        this.twoImg = 4;
      }
      if (index === 5) {
        this.threeImg = 5;
      }
    },
    handleImgOVer(index) {
      if (index === 3) {
        this.oneImg = 0;
      }
      if (index === 4) {
        this.twoImg = 1;
      }
      if (index === 5) {
        this.threeImg = 2;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.floating {
  position: relative;
  z-index: 99;

  box-sizing: border-box;
  &-menu {
    position: fixed;
    top: 25%;
    right: 32px;
    width: 132px;
    height: 344px;
    background-image: url("~@/assets/images/main/menu_bg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    &-item {
      color: #ffffff99;
      text-align: center;
      width: 116px;
      height: 32px;
      line-height: 32px;
      font-family: "Inter";
      font-weight: 700;
      font-size: 14px;
      font-style: normal;
      background-image: url("~@/assets/images/main/tab_bg.png");
      background-size: 100% 100%;
      cursor: pointer;
    }
    &-item + &-item {
      margin-top: 8px;
    }
    .active {
      color: #000000d9;
      background-image: url("~@/assets/images/main/tab_bg_active.png");
    }
  }

  &-bottom {
    position: fixed;
    bottom: 10%;
    right: 32px;
    height: 176px;
    width: 56px;

    &-list {
      width: 48px;
      height: 112px;
      background: #000000b3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      &-item {
        width: 32px;
        height: 32px;
        margin: 8px 0;
        cursor: pointer;
      }
    }
  }
  .backTop {
    margin-top: 8px;
    width: 56px;
    height: 56px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-height: 840px) {
  .floating {
    top: 25%;
    .backTop {
      bottom: 5%;
    }
  }
}
@media screen and (max-height: 600px) {
  .floating {
    top: 18%;
    .backTop {
      bottom: 2%;
    }
  }
}
</style>
